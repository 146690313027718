import React from 'react';
import ReactDOM from 'react-dom/client';
import { WorkspaceCanvas } from 'polotno/canvas/workspace-canvas';
import { createStore } from 'polotno/model/store';
import { toggleFadeInAnimation } from 'polotno/canvas/use-fadein';
import { setAssetLoadTimeout } from 'polotno/utils/loader';
import { addGlobalFont } from 'polotno/utils/fonts';
import { onLoadError } from 'polotno/utils/loader';
import {
  useHtmlTextRender,
  setTextOverflow,
  setTextVerticalResizeEnabled,
} from 'polotno/utils/flags';

toggleFadeInAnimation(false);
setTextOverflow('change-font-size');

const key = new URLSearchParams(window.location.search).get('key');

const store = createStore({
  key: key,
});

// preload roboto font
// because sometimes it doesn't work good with lazy loading
store.loadFont('Roboto');

window.store = store;
window.config = {
  addGlobalFont,
  useHtmlTextRender,
  setTextVerticalResizeEnabled,
  unstable_setTextOverflow: setTextOverflow,
  unstable_useHtmlTextRender: useHtmlTextRender,
  unstable_setTextVerticalResizeEnabled: setTextVerticalResizeEnabled,
  setAssetLoadTimeout,
  onLoadError,
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WorkspaceCanvas
    store={store}
    components={{ PageControls: () => null, Tooltip: () => null }}
  />
);
